@mixin respond-to($size) {
    @if $size == xs {
        @media all and (max-width: $screen-sm - 1) {
            @content;
        }
    } @else if $size == sm {
        @media all and (min-width: $screen-sm) {
            @content;
        }
    } @else if $size == md {
        @media all and (min-width: $screen-md) {
            @content;
        }
    } @else if $size == lg {
        @media all and (min-width: $screen-lg) {
            @content;
        }
    } @else if $size == xlg {
        @media all and (min-width: $screen-xlg) {
            @content;
        }
    } @else if $size == xxlg {
        @media all and (min-width: $screen-xxlg) {
            @content;
        }
    }
}

@mixin border-box {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}
