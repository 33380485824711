

// Detail Page Events
//.event-calendar
.event-details {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: $grid-gutter-width;
    background: #fff;

    /* Align container with slick-react-slider beneath */
    @include respond-to(lg) {
        margin: 25px 0;
        padding-bottom: 40px;
    }

    ul {
        list-style: none;
        /*margin: 0;
        padding: 0;
        li {
          padding: 0;
          margin: 0 0 .25em 0;
        }*/
    }

    table {
        width: 100%;
        margin: 1.0em 0;

        td:first-child {
            min-width: 40%;
            padding-right: $grid-gutter-width;
        }
    }

    @include respond-to(lg) {
        .event-details__shortinfo {
            float: left;
            width: 50%;
        }
        .event-details__contact {
            float: right;
            width: 50%;
            margin-bottom: 16px;
        }

    }

    #map {
        margin-bottom: 16px;
    }
}

.cright {
    position: absolute;
    right: 0;
    @media all and (min-width: 560px) {
        right: 10px;
    }
    top: 17%;
    font-size: 21px;
    cursor: pointer;
    @include respond-to(lg) {
        right: -58px;
        top: 47%;
    }

}

.cleft {
    position: absolute;
    left: 0;
    top: 17%;
    font-size: 21px;
    cursor: pointer;
    @include respond-to(lg) {
        left: -58px;
        top: 47%;
    }
}

.current-events-widget {
    .cleft,
    .cright {
        top: 24%;
        line-height: 1.0;
        padding: 10px;
    }

}

.event-calendar .carousel-wrapper,
.current-events-widget .carousel-wrapper {

    .slick-track {
        padding-bottom: 5px;

        &:after,
        &:before {
            display: none;
        }

        display: flex;
        align-items: stretch;
    }

    .siema {
        &[data-items="2"] {
            //padding: 0 1px;
            .event-item-wrapper {
                margin-right: 10px;
            }

            & > div > div:nth-child(odd) .event-item-wrapper {
                //margin: 0 5px 0 0;
            }

            & > div > div:nth-child(even) .event-item-wrapper {
                //margin: 0 0 0 5px;
            }
        }

        &[data-items="3"] {
            //padding: 0 1px;
            .event-item-wrapper {
                @media all and (min-width: 560px) {
                    margin-right: 10px;
                }
            }

            & > div > div:nth-child(even) .event-item-wrapper {
                //margin: 0 10px;
            }
        }

        &[data-items="4"] {
            //padding: 0 1px;
            .event-item-wrapper {
                @media all and (min-width: 560px) {
                    margin-right: 10px;
                }
            }

            & > div > div:nth-child(odd) .event-item-wrapper {
                //margin: 0 10px 0 0;
            }
        }
    }

    .siema .event-item-wrapper {
        // margin: 0 10px;
    }

    .siema > div > div {
        flex-grow: 0;
    }

    .siema > div {
        justify-content: flex-start;
    }

    .siema .data-content {
        min-height: 200px;
    }

    .event-container {
        position: relative;
        @media all and (min-width: 560px) {
            margin-right: -9px;
            padding: 1px;
        }
    }

    .event-item-wrapper {
        height: auto;
        background: #fff;
        box-shadow: 2px 1px 2px rgba(157, 150, 147, 0.3);

        position: relative;

        // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    .data-addtl {
        padding-top: 0;
    }

    .event-item {
        position: static;
        border: 0;
        height: 100%;

        .h3 {
            font-size: 1.4em;
            //padding: 0;
            //margin: 0 0 .5em 0;
            //line-height: 120%;
        }

        .icon-links li {
            margin-right: .5em;

            .icon {
                margin-top: 4px;
            }
        }
    }
}

.edetail {
    padding: $grid-gutter-width/2 $grid-gutter-width;
}

.edetail__data {
    display: flex;

    &::after {
        clear: both;
        display: block;
        content: '';
    }
}

.edetail__1 {
    float: left;
    width: 50%;
    position: relative;

    &::after {
        background: #eee;
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        width: 1px;
        content: '';
    }
}

.edetail__2 {
    width: 50%;
    float: left;
    padding-left: $grid-gutter-width;
}

.edetail__desc {
    padding: $grid-gutter-width 0;
}

.edetail__line {
    display: block;
}

.icon-links {
    padding: 0;

}

.link-item {
    overflow-x: hidden;
    height: 20px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
