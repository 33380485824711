.btn-sign-up {
  color: $event-button-color;
  cursor: pointer;
  font-size: 1em;
  -webkit-appearance: none;
  padding: spacing(0.5);
  line-height: 1em;
  border: 1px solid #cecece;
  background: #fff;
}

.btn-right {
  right: $grid-gutter-width;
  @include respond-to(md) {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

.btn-ghost {
  border: 1px solid;
  display: block;
}

.xdbtn--debug {
  background: transparent !important;
  position: absolute;
  left: 20px;
  border: 0;
  font-size: 1em;
}

.xdbtn--link {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}
