@-webkit-keyframes leaflet-gestures-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes leaflet-gestures-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.event-calendar--ew .leaflet-container:after,
.event-calendar .leaflet-container:after,
.event-details .leaflet-container:after,
.event-map--ew .leaflet-container:after {
  -webkit-animation: leaflet-gestures-fadein 0.8s backwards;
  animation: leaflet-gestures-fadein 0.8s backwards;
  color: #fff;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 22px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 461;
  pointer-events: none;
}

.leaflet-gesture-handling-touch-warning:after,
.leaflet-gesture-handling-scroll-warning:after {
  -webkit-animation: leaflet-gestures-fadein 0.8s forwards;
  animation: leaflet-gestures-fadein 0.8s forwards;
}

.leaflet-gesture-handling-touch-warning:after {
  content: attr(data-gesture-handling-touch-content);
}

.leaflet-gesture-handling-scroll-warning:after {
  content: attr(data-gesture-handling-scroll-content);
}
