@import "globals";
@import "components/icons";
@import "components/buttons";
@import "components/carousel";
@import "components/checkbox";
@import "components/event-details";
@import "components/map";
@import "components/search-results";
@import "components/event-filter";
@import "components/lists";
@import "components/register-form";
@import "components/date-picker";
@import "components/date-range-picker";
@import "components/search-input";
@import "widgets/single-event-widget";
@import "widgets/event-calendar";
@import "ui";

.widgetRoot {
  * {
    @include border-box;
  }
}

// only in dev mode
#root {
  width: 100%;
  margin: auto;
  @include respond-to(lg) {
    //width: 1280px;
  }
}
