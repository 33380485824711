.xdlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.xdlist--horizontal {
  > li {
    display: inline-block;
    &:not(:first-child) {
      margin-left: spacing(1);
    }
  }
}
