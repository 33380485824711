.flex-center {
    .filter-wrapper__content {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: nowrap;
    }

    @media all and (max-width: $screen-sm - 1) {
        .filter-elem:first-child .fly-out {
            left: 10%;
            transform: none;
        }
        .filter-elem:last-child .fly-out {
            right: 10%;
            left: auto;
            transform: none;
        }
    }
}

.event-calendar-head {
    border-bottom: 1px solid darkgrey;
    .filter-wrapper {
        width: 100%;
    }
}

.event-calendar {
    padding: spacing(1);

    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    textarea,
    .uneditable-input {
        font-size: 1.0em;
        -webkit-appearance: none;
        padding: 7px;
        line-height: 1.0em;
        border: 1px solid $event-border-color;
        background: $event-input-bg;
    }

    input[type="text"],
    select {
        height: $event-input-height;
    }
}

.event-calendar-content {
    margin: spacing(1) 0;
}

.input-group {
    display: flex;
    button {
        position: relative;
        background-color: $event-button-bg;
        width: $event-button-width;
    }
}

select {
    background-color: white;
    display: inline-block;
    font: inherit;
    line-height: 1;
    font-size: 1.0em;
    height: auto;
    width: auto;
    padding: 0.5em 3.5em 0.5em .2em;
    @include border-radius(0);

    /*
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;


      // Select Icon - arrow

      background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) .25em;
      background-size: 5px 5px,
      5px 5px,
      1px 2.0em;
      background-repeat: no-repeat;

      &:focus {
        background-image: linear-gradient(45deg, $event-border-color 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $event-border-color 50%),
        linear-gradient(to right, #ccc, #ccc);
        background-position: calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px,
        5px 5px,
        1px 1.5em;
        background-repeat: no-repeat;
        border-color: $event-border-color;
        outline: 0;
      }
      */
    // END: Select Icon - arrow

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}

.xdpull-left {
    float: left;

    .search-results & {
        float: none;
        @include respond-to(lg) {
            float: left;
        }
    }
}

.xdpull-right {
    float: right;
}

.clearfix::after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
}

.event-calendar,
.event-details {
    .status {
        display: inline-block;
        vertical-align: baseline;
        height: .75em;
        width: .75em;
        background-color: #ccc;

        border: 1px solid black;

        @include border-radius(50%);

        &.status--green {
            background-color: green;
        }

        &.status--red {
            background-color: red;
        }

        &.status--yellow {
            background-color: #ffb700;
        }
    }
}

.data-head {
    border-bottom: 1px solid $event-border-color;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .meta {
        display: inline-block;
        padding: $grid-gutter-width/2 $grid-gutter-width;
        font-weight: bold;
    }
}


