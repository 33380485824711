//.event-calendar {
.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 2px 0 2px 34px;
        font-size: .8em;
        text-transform: uppercase;
        line-height: 120%;
        height: 2.2em;
        display: table-cell;
        vertical-align: middle;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 7px;
        display: inline-block;
        vertical-align: center;
        width: 24px;
        height: 24px;
        float: left;
        background: white;
        border: 1px solid black;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    // Box hover
    //&:hover + label:before {
    //  background: $event-checkbox-hover;
    //}

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: $event-checkbox-active;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    /*&:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow:
              2px 0 0 white,
              4px 0 0 white,
              4px -2px 0 white,
              4px -4px 0 white,
              4px -6px 0 white,
              4px -8px 0 white;
      transform: rotate(45deg);
    }*/
}

.unstyled {
    margin: 0;
    padding: 0;
    list-style-type: none;
}


//}
