$grid-gutter-width: 16px !default;
$event-border-color: #cecece;

$event-link-bg-active: #cecece;
$event-link-color: #cecece;
$event-link-color-active: #333;

$event-button-bg: #ececec;
$event-button-color: $event-link-color-active;

$event-input-bg: #fff;
$event-select-bg: #fff;

$event-checkbox-hover: blue;
$event-checkbox-active: gray;

$event-input-height: 36px;

$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xlg: 1280px;
$screen-xxlg: 1680px;

$event-button-width: 95px;

$content-width--ew: 1024px;
