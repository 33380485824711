.current-events-widget,
.event-calendar {

  .search-results {
    display: flex;
    flex-wrap: wrap;
    @include respond-to(lg) {
      flex-wrap: nowrap;
    }
  }

  .search-results-head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
  }

  .search-results-head--ew {
    justify-content: space-between;
  }

  .search-results-content {
    width: 100%;
    @include respond-to(lg) {
      flex: 1 1 75%;
      padding-right: $grid-gutter-width;
      order: 0;
    }
  }

  .search-results-content--ew {
    padding: 0;
  }

  .search-results-filter {
    width: 100%;
    @include respond-to(lg) {
      margin-top: 44px;
      flex: 1 1 25%;
      padding-left: $grid-gutter-width;
      order: 1;
    }
  }

  .event-results-no-geolocation,
  .event-results-count-geolocation {
    margin: auto;
    width: fit-content;
    padding: 25px;
  }


  // List
  //.event-calendar {
  .search-results {
    .results-grid,
    .results-list {
      width: 100%;

      .event-item {
        background: #fff;
        display: flex;
      }

      > .event-item-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
  .results-list .event-item {
    margin: 0 0 $grid-gutter-width;
    padding: 0;
    width: 100%;
    display: block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .results-grid .event-item {
    $margin: 2%;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    flex-direction: column;

    margin-bottom: 15px;

    @include respond-to(sm) {
      float: left;
      width: 48%;
    }
    @include respond-to(lg) {
      width: ((100% -($margin*2)) / 3);
    }
    /*@include respond-to(xxlg) {
      width: ((100% -($margin*3)) / 4);
    }*/

  }

  .event-item {
    border: 1px solid $event-border-color;
    position: relative;

    .data-addtl,
    .data-content {
      //clear: both;
      //padding: $grid-gutter-width;
      padding: .5em 1em .2em 1em;


    }
  }
  .data-content {
    .meta {
      margin-bottom: .5em;
      display: inline-block;
    }
  }

  //}

  .results-list {

    .event-item__image {
      display: none;
      vertical-align: top;
      overflow: hidden;
      position: relative;
    }

    .event-item__data {
      width: 100%;
    }

    // responsive sizes
    @include respond-to(md) {
      .event-item__image {
        float: left;
        width: 30%;
        display: block;
        position: relative;
        /*max-height: 190px;
        overflow: hidden;*/

        img {
          //transform: translate(-20%,0);

          min-height: 260px;
          max-height: 265px;

        }

        /* &:before {
           content: "";
           display: block;
           padding-top: 100%;
         }*/
      }
      .data-head,
      .data-content,
      .event-item_footer {
        //.event-item__data {
        width: 70%;
        float: right;
      }
    }
    @include respond-to(lg) {
      .event-item__image {
        width: 36%;
      }
      .data-head,
      .data-content,
      .event-item_footer {
        //.event-item__data {
        width: 64%;
      }
    }
    @include respond-to(xxlg) {
      .event-item__image {
        width: 30%;
      }
      .data-head,
      .data-content,
      .event-item_footer {
        //.event-item__data {
        width: 70%;
      }
    }
  }
  .event-item_footer {
    .results-list & {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 70px;
      margin-top: auto;
      //padding-bottom:  $grid-gutter-width;
    }

    .btn-sign-up {
      margin-right: $grid-gutter-width;
    }
  }
}




/* Grid styling */
.event-calendar .results-grid {
  .event-item__data {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .event-item__image {
    display: block;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    clear: both;

    img {
      width: 100%;
      min-height: 160px;
      max-height: 200px;
    }
  }

  .data-footer {
    text-align: center;
    margin: .4em 0
  }

  .icon-links li {
    margin-right: .25em;

    a {
      .icon {
        margin-top: 4px;
      }
    }
  }
}

