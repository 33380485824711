.date-range-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.date-range-picker-p {
  margin: spacing(0.5) 0;
  width: 50%;
  @include respond-to(md) {
    max-width: 32%;
  }
}

.date-range-select {
  width: 100%;
  select {
    width: 100%;
  }
}

