.register-form {
  .form-group.required label::after,
  .form-check.required label::after,
  .agb__label::after {
    content: ' *';
    color: red;
    font-size: .8em;
    font-weight: normal
  }

  .form-group input {
    border: 1px solid #ddd;
    padding-left: .4em;

    &:required {
      //border-left: 4px solid red;
      //content: '*';
    }
  }

  .xdlegend::before {
    content: '* ';
    color: red;
    font-size: .8em;
  }

  .agb {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    padding-right: 2em;
    border-bottom: 1px solid #efefef;
    margin-bottom: 1em;
  }

  .agb__checkbox {
    display: inline-block;
  }

  .agb__label {
    display: inline-block;
  }

  .xdbtn--submit {
    float: right;
    background: #ececec;
    padding: .5em;
    margin-left: 10px;
  }

  .fx-closed {
    height: 0;
    overflow: hidden;
  }

  .xderror {
    border: 1px solid red !important;
  }

  .status-field {
    font-weight: bold;
    text-align: center;
  }

  .radio-group {
    display: flex;
    flex-direction: column;
  }
}

/* compact form view */

.compact {
  height: 60vh;
  overflow-y: auto;

  .register-form {
    margin: 10px auto;
    border-radius: 3px;
    padding: spacing(1.5) spacing(0);
    background: #fff;

    .field {
      width: 100%;
      box-sizing: border-box;
      height: 30px;
      margin-bottom: 0;
    }

    &::after {
      clear: both;
      display: table;
      content: "";
    }
  }

  .journal-content-article .form-group {
    display: block;
  }

  .form-group {
    margin-bottom: 20px;

    &:nth-child(1) {
      width: 49%;
    }

    &:nth-child(2) {
      width: 49%;
      margin-right: 1%;
      float: left;
    }

    &:nth-child(3) {
      width: 49%;
      margin-left: 1%;
      float: left;
    }

    &:nth-child(4) {
      clear: both;
    }

    &:nth-child(5) {
      width: 49%;
      margin-right: 1%;
      float: left;
    }

    &:nth-child(6) {
      width: 49%;
      margin-left: 1%;
      float: left;
    }

    &:nth-child(7) {
      width: 49%;
      margin-right: 1%;
      float: left;
    }

    &:nth-child(8) {
      width: 49%;
      margin-left: 1%;
      float: left;
    }

    &:nth-child(n+9) {
      clear: both;
    }
  }

  .form-check {
    clear: both;
    margin-bottom: 20px;
  }

  fieldset {
    min-width: 1px;
    margin-bottom: 20px;

    &.radio-group > legend {
      font-size: 14px;
      color: #869cad;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin: 0 .4em 0 0;
    vertical-align: middle;
  }

  .portlet-decorate .portlet-content {
    background: none;
  }

  .xdh2 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0 0 1em 0;
    display: block;
    text-align: center;
  }

  .xdbtn--bar {
    position: relative;
    height: 35px;
    margin-top: spacing(2);
  }
}

