.slick-slider {
    .data-footer {
        text-align: center;
        margin: .4em 0
    }
}

.current-events-widget .event-item__image {
    max-height: 200px;
    overflow: hidden;
}
