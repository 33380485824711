.event-calendar {

    .filter-wrapper {
        margin: spacing(1) 0;

    }
    .filter-wrapper__title {
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 15px;

        .inner-title {
            /*background-color: #fff;*/
            position: relative;
            z-index: 4;
            padding-right: $grid-gutter-width;
            white-space: nowrap;
            display: flex;
        }

        @include respond-to(sm) {
            display: flex;
            align-items: center;
            // justify-content:
            position: relative;

            &::after {
                z-index: 3;
                content: "";
                height: 1px;
                background-color: $event-border-color;
                width: 100%;
                /*position: absolute;
                left: 0;
                right: 0;
                top: 50%;*/
            }
        }
    }

    .filter-wrapper__content {
        .input-group {
            width: 100%;
            margin-bottom: 7px;
            justify-content: space-between;
        }

        input[type="text"] {
            margin-bottom: 0;
            /* width: calc(100% - #{$event-button-width} - 14px);*/
            width: 100%;
        }
    }

    .filter-icon-wrapper {
        text-align: center;
    }

    .filter-elem {
        padding: 0 10px 10px 10px;
        display: inline-block;
        position: relative;

        &.disabled {
            filter: grayscale(1);
            opacity: .4;
            pointer-events: none;
        }

        &.selected {
            .icon-round .xdicon {
                background: #c7d530;
            }
        }

        .filter-title {
            text-align: center;

            a {
                display: flex;
            }

            span {
                &:first-child {
                    margin-right: .2em;
                }
            }
        }
    }

    .fly-out {
        position: absolute;
        z-index: 9;
        width: auto;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border: 1px solid $event-border-color;
        background-color: white;

        .fly-out__title {
            padding: 10px 10px 0 10px;
            text-align: center;
            text-transform: uppercase;
            display: block;
        }

        .filter-list > li {
            width: 100%;
            padding-left: 0 !important;
            padding-right: 7px !important;
        }

        ul {
            padding: 10px 10px 6px 10px;
            list-style: none;
        }

        li {
            label {
            }
        }
    }

    .arrow:after, .arrow:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .arrow:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #fff;
        border-width: 12px;
        margin-left: -12px;
    }

    .arrow:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: $event-border-color;
        border-width: 14px;
        margin-left: -14px;
    }

    .filter-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @include respond-to(lg) {
            display: block;
        }

        > li {
            width: 50%;

            &:nth-child(odd) {
                padding-right: 7px;
            }

            &:nth-child(even) {
                padding-left: 7px;
            }

            margin-bottom: 11px;
            @include respond-to(md) {
                width: 33%;
                &:nth-child(odd),
                &:nth-child(even) {
                    padding-left: 0;
                    padding-right: 0;
                }
                &:nth-child(3n+0) { // jedes dritte Element
                    padding-left: 7px;
                }
                &:nth-child(3n+2) { // jedes dritte Element ab dem 2. (also mittleres Element bei einer 33er Verteilung
                    padding-left: 7px;
                }
            }
            @include respond-to(lg) {
                clear: both;
                float: none;
                width: 100%;
                &:nth-child(odd),
                &:nth-child(even),
                &:nth-child(3n+0),
                &:nth-child(3n+2) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}
