.search-input-wrapper {
  margin: spacing(1) 0;
}

.search-input {
  display: flex;
  width: 100%;
  input {
    width: 100%;
  }
}

.btn-search {
  display: flex;
  align-items: center;
  font-size: 1.0em;
  -webkit-appearance: none;
  padding: 7px;
  line-height: 1.0em;
  border: 1px solid $event-border-color;
  background-color: $event-button-bg;
  .xdicon {
    padding-right: 1em;
  }
  color: #000;
}
