.single-event-widget {
    .event-item-wrapper {
        height: auto;
        background: #fff;
        box-shadow: 2px 1px 2px rgba(157, 150, 147, 0.3);

        position: relative;
    }

    .event-item {
        .event-item__data {
            .more-link[class*='icon-custom'].icon {
                width: calc(100% - 1em)
            }
        }

        .data-content > p {
            height: 120px;
        }
    }
}
